import React, { useEffect } from 'react';

const Preloader = () => {
  useEffect(() => () => {
    document.querySelectorAll('.preloader').forEach(preloader => {
      const loader = preloader;
      loader.style.opacity = 0;
      loader.style.visibility = 'hidden';
    });
  });
  return (
    <div className="preloader">
      <div className="spinner">
        <div className="double-bounce1" />
        <div className="double-bounce2" />
      </div>
    </div>
  );
};

export default React.memo(Preloader);
