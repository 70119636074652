import axios from 'axios';
import { toast } from 'react-toastify';
import appConfig from 'config';
import util from './util';

const http = axios.create({ baseURL: appConfig.API_URL });

http.interceptors.request.use(config => {
  const httpConfig = { ...config };
  const token = util.getToken();
  if (token && util.isTokenExpired(token)) {
    return util.refreshToken(token)
      .then(data => {
        const { token: newToken } = data;
        util.setToken(newToken);
        httpConfig.headers.Authorization = `Bearer ${newToken}`;
        return Promise.resolve(httpConfig);
      });
  }
  if (token) httpConfig.headers.Authorization = `Bearer ${token}`;
  return httpConfig;
});

http.interceptors.response.use(resp => resp.data, err => {
  if (err.response.status === 401) {
    util.logout();
  }

  let errorMessage = [];
  if (err.response.status !== 422) {
    errorMessage.push(err.response.data.message || err.response.statusText);
  } else {
    const errors = err.response.data;
    errorMessage = Object.keys(errors).map(key => errors[key][0]);
  }
  toast.error(errorMessage.join(', '));
  return Promise.reject(err);
});

export default http;
