import React, { lazy, Suspense, useEffect } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import { util } from 'helpers';
import Preloader from 'components/Preloader';

const Login = lazy(() => import('pages/Auth/Login'));
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'));
const PrintTransaction = lazy(() => import('pages/Transaction/PrintTransaction'));
const Layout = lazy(() => import('components/Layout'));

function App() {
  useEffect(() => {
    setTimeout(() => {
      const preloader = document.getElementById('preloader');
      preloader.style.opacity = 0;
      preloader.style.visibility = 'hidden';
    });
  }, []);

  toast.configure({
    autoClose: 3000,
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    closeButton: false,
  });
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/login"
          render={() => (util.getToken() ? (
            <Redirect to="/" />
          ) : (
            <Suspense fallback={<Preloader />}>
              <Login />
            </Suspense>
          ))}
        />
        <Route
          exact
          path="/forgot-password"
          render={() => (util.getToken() ? (
            <Redirect to="/" />
          ) : (
            <Suspense fallback={<Preloader />}>
              <ForgotPassword />
            </Suspense>
          ))}
        />
        <Route
          exact
          path="/reset-password"
          render={() => (util.getToken() ? (
            <Redirect to="/" />
          ) : (
            <Suspense fallback={<Preloader />}>
              <ResetPassword />
            </Suspense>
          ))}
        />
        <Route
          exact
          path="/transactions/print/:id"
          render={() => (!util.getToken() ? (
            <Redirect to="/login" />
          ) : (
            <Suspense fallback={<Preloader />}>
              <PrintTransaction />
            </Suspense>
          ))}
        />
        <Route
          exact
          path="/transactions/prints"
          render={() => (!util.getToken() ? (
            <Redirect to="/login" />
          ) : (
            <Suspense fallback={<Preloader />}>
              <PrintTransaction />
            </Suspense>
          ))}
        />
        <Route
          path="/"
          render={() => (!util.getToken() ? (
            <Redirect to="/login" />
          ) : (
            <Suspense fallback={<Preloader />}>
              <Layout />
            </Suspense>
          ))}
        />
      </Switch>
    </Router>
  );
}

export default App;
