import axios from 'axios';
import jwtDecode from 'jwt-decode';
import config from '../config';

const { TOKEN_STORAGE, API_URL } = config;

const getTokenExpirationDate = token => {
  const decoded = jwtDecode(token);

  if (!Object.prototype.hasOwnProperty.call(decoded, 'exp')) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(decoded.exp);

  return date;
};

export default {
  getUser: () => {
    const token = localStorage.getItem(TOKEN_STORAGE);
    return jwtDecode(token).data;
  },
  setUser: user => localStorage.setItem(`${config.APP_NAME}USER`, JSON.stringify(user)),
  setToken: token => localStorage.setItem(TOKEN_STORAGE, token),
  getToken: () => localStorage.getItem(TOKEN_STORAGE),
  isTokenExpired: token => {
    if (!token) {
      return true;
    }
    const date = getTokenExpirationDate(token);
    if (!date) {
      return true;
    }
    return !(date.valueOf() > new Date().valueOf());
  },
  refreshToken: async token => {
    const url = `${API_URL}refresh-token`;
    const response = await axios.post(url, { token });
    return response.data;
  },
  logout: () => {
    localStorage.removeItem(TOKEN_STORAGE);
    localStorage.removeItem(`${config.APP_NAME}USER`);
    window.location.replace('/login');
  },
  setTransactionIds: transactionIds => localStorage.setItem('transactionIds', JSON.stringify(transactionIds)),
  getTransactionIds: () => JSON.parse(localStorage.getItem('transactionIds') || '[]'),
};
